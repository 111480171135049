import React from 'react';
import { FullLogo } from '../HomeLogo';
import ProjectsSection from './ProjectsSection';
import FeatureBoxes from './FeatureP5';
import HeroSection from './LandingHero';
import SketchesSection from './SketchesSection';
import usePatreon from '../../utils/usePatreon';
import { TopBarAnchor, TopBarButtons } from '../__TopBar';


function Header() {
    const patreon = usePatreon()
    const logIn = () => {
        window.location.href = getPatreonLogInUrl()
    }
    const logOut = () => {
        patreon.logOut()
    }
    return (
        <header className="flex justify-between items-center p-4 bg-gray-200">
            <FullLogo width="30px" height="30px" />
            <nav className='flex gap-4'>
                <TopBarButtons>
                    <TopBarAnchor href="#home" className="mx-2">home</TopBarAnchor>
                    <TopBarAnchor href="#projects" className="mx-2">projects</TopBarAnchor>
                    <TopBarAnchor href="#sketches" className="mx-2">sketches</TopBarAnchor>
                    <TopBarAnchor href="#about" className="mx-2">about</TopBarAnchor>
                </TopBarButtons>
                {patreon.username
                    ? <button className="bg-gray-300 px-3 py-1 rounded" onClick={() => logOut()}>logout</button>
                    : <button className="bg-gray-300 px-3 py-1 rounded" onClick={() => logIn()}>login</button>
                }
            </nav>
        </header>
    );
}

const AboutSection = () => (
    <section className="flex items-center p-8 bg-gray-200 justify-between">
        <div>
            <h2 className="text-3xl font-bold mb-4">ABOUT</h2>
            <p>Creative Technologist | Designer | Maker | Educator | A Dad </p>
            <p>1987 - present | Israel | orrkislev@gmail.com</p>
        </div>
        <div className="ml-8 relative flex items-center gap-4">

            <div className="w-8 h-8">
                <svg className="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
                </svg>
            </div>

            <div className="w-8 h-8">
                <svg className="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                </svg>
            </div>

            <div className="w-8 h-8">
                <svg className="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                </svg>
            </div>

            <div className="w-8 h-8">
                <svg className="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.483 10.505a.375.375 0 00-.53.53l1.723 1.723a.375.375 0 00.53 0l1.723-1.723a.375.375 0 00-.53-.53l-1.458 1.458-1.458-1.458zm-2.966 0a.375.375 0 01.53.53l-1.723 1.723a.375.375 0 01-.53 0L7.071 11.035a.375.375 0 11.53-.53l1.458 1.458 1.458-1.458zm12.135 2.495a11.972 11.972 0 01-11.652 11.652 11.972 11.972 0 01-11.652-11.652A11.972 11.972 0 0112 1.348a11.972 11.972 0 0111.652 11.652zm-1.5 0A10.472 10.472 0 0012 2.848 10.472 10.472 0 001.528 13.32 10.472 10.472 0 0012 23.792 10.472 10.472 0 0022.472 13.32zM12 7.82a5.5 5.5 0 100 11 5.5 5.5 0 000-11zm0 1.5a4 4 0 110 8 4 4 0 010-8z" />
                </svg>
            </div>

            <button className="w-8 h-8">
                <svg className="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 5.457v13.909c0 .904-.732 1.636-1.636 1.636h-3.819V11.73L12 16.64l-6.545-4.91v9.273H1.636A1.636 1.636 0 0 1 0 19.366V5.457c0-2.023 2.309-3.178 3.927-1.964L5.455 4.64 12 9.548l6.545-4.91 1.528-1.145C21.69 2.28 24 3.434 24 5.457z" />
                </svg>
            </button>

            <img src="portrait.png" alt="portrait" className="w-64 h-64 rounded-full" />
        </div>
    </section>
);

export default function LandingPage() {
    return (
        <div className="min-h-screen bg-gray-100 landing-main">
            <Header />
            <HeroSection />
            <FeatureBoxes />
            <ProjectsSection />
            <SketchesSection />
            <AboutSection />
        </div>
    )
}